import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { facilitiesPropType, invoiceForm } from "../../../constants";
import { required } from "../../../Helpers/validators";
import { downloadFacilityInvoice } from "../../../Helpers/FacilityInvoice";
import FacilityField from "./FormElements/FacilityField";
import Debounce from "../../../Helpers/Debounce";
import PropTypes from "prop-types";
import { getSearchFacilities } from "../../../Redux/Actions/actions";

const debouncer = new Debounce({ period: 500 });

const FacilityInvoice = ({ facilitiesList, getFacilities }) => {
  const [fetching, setFetching] = useState(false);
  const [facility, setFacility] = useState(null);

  useEffect(() => {
    getFacilities();
  }, [getFacilities]);

  return (
    <div>
      <strong>Invoices</strong>
      <p>Downloads invoice for the selected organization</p>
      <div className="row">
        <div className="col-md-6">
          <div className="mt-3">
            <Field
              component={FacilityField}
              validate={[required]}
              setFacility={setFacility}
              facilitiesList={facilitiesList}
              getFacilities={getFacilities}
            />
          </div>

          <div className="mt-4">
            <button
              className="btn btn-primary py-2"
              disabled={fetching || !facility}
              onClick={() => {
                setFetching(true);
                downloadFacilityInvoice(facility).then(() =>
                  setFetching(false)
                );
              }}
            >
              {fetching && (
                <span
                  className="spinner-border spinner-border-sm align-middle mr-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Download Invoice
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

FacilityInvoice.propTypes = {
  facilitiesList: PropTypes.arrayOf(facilitiesPropType),
  getFacilities: PropTypes.func.isRequired,
};

FacilityInvoice.defaultProps = {
  facilitiesList: [],
};

const mapStateToProps = (state) => ({
  facilitiesList: state.facilities.facilityTableData.map((facility) => ({
    value: String(facility.id),
    label: facility.name,
  })),
});

const mapDispatchToProps = (dispatch) => ({
  getFacilities: (inputValue) =>
    debouncer.do(
      (searchInput) => dispatch(getSearchFacilities(searchInput, 1, 10)),
      inputValue
    ),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: invoiceForm,
  })
)(FacilityInvoice);
